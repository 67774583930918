<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="171" viewBox="0 0 457.094 319.114" class="logo">
	  <g id="Gruppe_2831" data-name="Gruppe 2831" transform="translate(-463.339 -196.353)">
		<g id="Gruppe_2823" data-name="Gruppe 2823" transform="translate(463.339 200.546)">
		  <g id="Gruppe_2818" data-name="Gruppe 2818" transform="translate(0 0.202)">
			<g id="Gruppe_2816" data-name="Gruppe 2816" opacity="0" style="mix-blend-mode: multiply;isolation: isolate">
			  <g id="Polygon_3-2" data-name="Polygon 3-2">
				<path id="Pfad_3655" data-name="Pfad 3655" d="M537.1,447.862c-89.013-24.64-89.013-150.861,0-175.508l220.308-60.985A91.051,91.051,0,0,1,872.749,299.12V421.1a91.05,91.05,0,0,1-115.342,87.751Z" transform="translate(-467.252 -204.941)"/>
				<path id="Pfad_3656" data-name="Pfad 3656" d="M777.785,511.3a94.136,94.136,0,0,1-25.115-3.412L532.362,446.9c-21.613-5.983-39.512-18.272-51.763-35.539a96.86,96.86,0,0,1,0-110.372c12.251-17.27,30.151-29.561,51.765-35.546L752.67,204.458a94.139,94.139,0,0,1,119.253,90.725V417.16A94.138,94.138,0,0,1,777.785,511.3Zm-.027-304.088a87.751,87.751,0,0,0-23.442,3.2L534.009,271.393c-20.229,5.6-36.957,17.071-48.377,33.168a90.687,90.687,0,0,0,0,103.227c11.419,16.1,28.147,27.563,48.375,33.163l220.308,60.985A87.965,87.965,0,0,0,865.749,417.16V295.183a87.976,87.976,0,0,0-87.991-87.974Z" transform="translate(-463.339 -201.004)"/>
			  </g>
			</g>
			<g id="Gruppe_2817" data-name="Gruppe 2817">
			  <g id="Polygon_3-2-2" data-name="Polygon 3-2">
				<path id="Pfad_3657" data-name="Pfad 3657" d="M537.1,447.862c-89.013-24.64-89.013-150.861,0-175.508l220.308-60.985A91.051,91.051,0,0,1,872.749,299.12V421.1a91.05,91.05,0,0,1-115.342,87.751Z" transform="translate(-467.252 -204.941)" fill="none"/>
				<path id="Pfad_3658" data-name="Pfad 3658" d="M777.785,511.3a94.136,94.136,0,0,1-25.115-3.412L532.362,446.9c-21.613-5.983-39.512-18.272-51.763-35.539a96.86,96.86,0,0,1,0-110.372c12.251-17.27,30.151-29.561,51.765-35.546L752.67,204.458a94.139,94.139,0,0,1,119.253,90.725V417.16A94.138,94.138,0,0,1,777.785,511.3Zm-.027-304.088a87.751,87.751,0,0,0-23.442,3.2L534.009,271.393c-20.229,5.6-36.957,17.071-48.377,33.168a90.687,90.687,0,0,0,0,103.227c11.419,16.1,28.147,27.563,48.375,33.163l220.308,60.985A87.965,87.965,0,0,0,865.749,417.16V295.183a87.976,87.976,0,0,0-87.991-87.974Z" transform="translate(-463.339 -201.004)" fill="#009dff"/>
			  </g>
			</g>
		  </g>
		  <g id="Gruppe_2822" data-name="Gruppe 2822" transform="translate(43.877)">
			<g id="Gruppe_2819" data-name="Gruppe 2819" transform="translate(3.307 3.307)" opacity="0" style="mix-blend-mode: multiply;isolation: isolate">
			  <path id="Pfad_3659" data-name="Pfad 3659" d="M905.989,272.331c89.013,24.64,89.013,150.862,0,175.508L685.681,508.824a91.051,91.051,0,0,1-115.343-87.751V299.1a91.051,91.051,0,0,1,115.343-87.751l51.25,14.187,58.655,16.237Z" transform="translate(-570.338 -208.046)" stroke="#000" stroke-miterlimit="10" stroke-width="15"/>
			</g>
			<g id="Gruppe_2821" data-name="Gruppe 2821">
			  <g id="Gruppe_2820" data-name="Gruppe 2820">
				<path id="Pfad_3660" data-name="Pfad 3660" d="M905.989,272.331c89.013,24.64,89.013,150.862,0,175.508L685.681,508.824a91.051,91.051,0,0,1-115.343-87.751V299.1a91.051,91.051,0,0,1,115.343-87.751l52.984,14.667L812.2,246.369l18.562,5.138Z" transform="translate(-567.031 -204.739)" fill="#e6ebf0" stroke="#e6ebf0" stroke-width="7"/>
				<path id="Pfad_3661" data-name="Pfad 3661" d="M657.05,511.282a94.406,94.406,0,0,1-94.212-94.4V294.9A94.359,94.359,0,0,1,682.37,203.966L771.9,228.748l130.782,36.2c21.662,6,39.6,18.315,51.884,35.625a97.079,97.079,0,0,1,0,110.627c-12.281,17.312-30.223,29.633-51.886,35.631L682.37,507.819A94.865,94.865,0,0,1,657.05,511.282Zm.145-304.121A87.744,87.744,0,0,0,569.453,294.9V416.881a87.713,87.713,0,0,0,111.153,84.563l220.308-60.985c20.179-5.587,36.865-17.027,48.255-33.083a90.468,90.468,0,0,0,0-102.973c-11.39-16.054-28.076-27.492-48.254-33.077L680.606,210.341A87.756,87.756,0,0,0,657.2,207.161Z" transform="translate(-562.838 -200.546)" fill="none"/>
			  </g>
			</g>
		  </g>
		</g>
		<g id="Gruppe_2824" data-name="Gruppe 2824" transform="translate(539.533 315.992)">
		  <path id="Pfad_3662" data-name="Pfad 3662" d="M658.209,512.971V536.1H636.125V462.344h26.356a32.847,32.847,0,0,1,8.854,1.25,22.128,22.128,0,0,1,8.073,4.167,21.4,21.4,0,0,1,5.834,7.708,28.278,28.278,0,0,1,2.24,11.98,28.98,28.98,0,0,1-2.136,11.719,22.711,22.711,0,0,1-5.573,7.917,21.429,21.429,0,0,1-7.813,4.479,28.778,28.778,0,0,1-8.854,1.406Zm0-31.772v13.646h2.708a5.49,5.49,0,0,0,4.011-1.89,7.1,7.1,0,0,0,1.823-5.144q0-3.569-1.823-5.091a6.122,6.122,0,0,0-4.011-1.522Z" transform="translate(-636.125 -462.344)" fill="#232529"/>
		  <path id="Pfad_3663" data-name="Pfad 3663" d="M780.668,525.888H767.959L765.98,536.1h-22.6l17.4-73.753h28.022l17.5,73.753H782.647Zm-10.209-17.5h7.5l-3.437-23.959h-.417Z" transform="translate(-696.081 -462.344)" fill="#232529"/>
		  <path id="Pfad_3664" data-name="Pfad 3664" d="M907.446,536.1H885.154V511.825L866.3,462.344h24.48l5.521,27.189h.313l5.833-27.189H926.51l-19.064,49.481Z" transform="translate(-764.798 -462.344)" fill="#232529"/>
		  <path id="Pfad_3665" data-name="Pfad 3665" d="M1014.2,536.1l-17.5-73.753h25.834l6.667,46.564h.313l6.459-46.564h24.688L1042.74,536.1Z" transform="translate(-837.693 -462.344)" fill="#232529"/>
		  <path id="Pfad_3666" data-name="Pfad 3666" d="M1152.6,462.344h39.377v19.9h-17.813v7.4h16.146v18.438h-16.146v7.709h19.167V536.1H1152.6Z" transform="translate(-924.85 -462.344)" fill="#232529"/>
		  <path id="Pfad_3667" data-name="Pfad 3667" d="M1262.923,536.1V462.344h23.75a38.251,38.251,0,0,1,15.366,2.761,26.271,26.271,0,0,1,10.208,7.656,31.043,31.043,0,0,1,5.677,11.719,59.437,59.437,0,0,1,1.771,14.949,57.757,57.757,0,0,1-1.771,14.74,30.571,30.571,0,0,1-5.677,11.563,26.482,26.482,0,0,1-10.208,7.6,38.222,38.222,0,0,1-15.366,2.76Zm33.96-36.668q0-8.227-2.6-12.761t-7.187-4.531h-1.354V516.3h1.354q4.583,0,7.187-4.531T1296.883,499.429Z" transform="translate(-986.521 -462.344)" fill="#232529"/>
		</g>
	  </g>
	</svg>
</template>
<style scoped lang="scss">
.logo
{
    width: 85px;
    height: 65px;

    @media screen and (min-width: 992px)
    {
        width: 170px;
        height: 130px;
    }
}
</style>
